<template>
  <div>
    <b-row
      v-if="isMobile == false"
      class="mb-2"
    >
      <b-col
        cols="3"
        class="text-center font-weight-bolder"
      >
        WEEK
      </b-col>
      <b-col
        cols="2"
        class="text-center font-weight-bolder"
      >
        OPERATIVE INVOICE
      </b-col>
      <b-col
        cols="2"
        class="text-center font-weight-bolder"
      >
        SCL FEE INVOICE
      </b-col>
    </b-row>
    <b-row
      v-for="(invoice, index) in invoices.payments"
      :key="index"
    >
      <invoiceListItemComponent
        :week-details="invoice.paybatch[0].year + ' / Week No.: ' + invoice.paybatch[0].weekno"
        :operative-invoice-no="getOperativeInvoiceNo(invoice)"
        :operative-invoice-id="getOperativeInvoiceId(invoice)"
        :scl-invoice-no="getSCLInvoiceNo(invoice)"
        :scl-invoice-id="getSCLInvoiceId(invoice)"
        class="mb-2"
      />
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol,
} from 'bootstrap-vue'
import jwt from '@/auth/jwt/sclUseJwt'
import { mapGetters } from 'vuex'
import invoiceListItemComponent from '@/components/operatives/ui/InvoiceListItemComponent.vue'

export default {

  name: 'OperativeInvoices',
  components: {
    invoiceListItemComponent,
    BCol,
    BRow,
  },
  data() {
    return {
      isMobile: false,
      invoices: [],
    }
  },
  computed: {
    ...mapGetters({ opInfo: 'app/getCurrentOperative' }),
  },
  created() {
    console.log(this.opInfo.operativedetails[0].ent_id)
    if (this.opInfo.operativedetails[0].ent_id > -1) {
      jwt.axiosIns.get(`/invoices/${this.opInfo.operativedetails[0].ent_id}/`)
        .then(response => {
          this.invoices = JSON.parse(JSON.stringify(response.data))
        })
        .catch(e => {
          console.error(e)
        })
    }
    /* eslint-disable */
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent) || screen.availWidth < 480) {
      this.isMobile = true
    }
    /* eslint-enable */
  },
  methods: {
    getOperativeInvoiceNo(invoice) {
      if (invoice.opinv[0] === undefined) {
        return ''
      }
      return String(invoice.opinv[0].invno)
    },
    getOperativeInvoiceId(invoice) {
      if (invoice.opinv[0] === undefined) {
        return ''
      }
      return String(invoice.opinv[0].id)
    },
    getSCLInvoiceNo(invoice) {
      if (invoice.feeinv[0] === undefined) {
        return ''
      }
      return String(invoice.feeinv[0].invno)
    },
    getSCLInvoiceId(invoice) {
      if (invoice.feeinv[0] === undefined) {
        return ''
      }
      return String(invoice.feeinv[0].id)
    },
  },
}
</script>

<style scoped>

</style>
