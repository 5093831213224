<template>
  <div class="full-width">
    <b-card v-if="isMobile == false">
      <operativeInvoices />
    </b-card>
    <operativeInvoices v-else />
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import operativeInvoices from '@/components/operatives/OperativeInvoice.vue'

export default {
  middleware: ['check-auth', 'auth'],
  components: {
    BCard,
    operativeInvoices,
  },
  data() {
    return {
      isMobile: false,
    }
  },
  created() {
    /* eslint-disable */
    if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent) || screen.availWidth < 480) {
      this.isMobile = true
    }
    /* eslint-enable */
  },
}
</script>

<style>

</style>
